import {
  Box,
  Container,
  Flex,
  Icon,
  IconButton,
  Stack,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { FiInstagram, FiMenu, FiXCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { LogoSVG } from "../images/LogoSVG";
import useAnalyticsEventTracker from "../Utils/useAnalyticsEventTracker";

export const NavBar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Box height={2} bgGradient="linear(to-r, blue.200, teal.500)" />
      <NavBarContainer>
        <MenuToggle toggle={toggle} isOpen={isOpen} setIsOpen={setIsOpen} />
        <MenuLinks isOpen={isOpen} setIsOpen={setIsOpen} />
      </NavBarContainer>
    </>
  );
};

const MenuToggle = ({ toggle, isOpen, setIsOpen }) => {
  const logoColor = useColorModeValue("black", "white");
  const gaEventTracker = useAnalyticsEventTracker("NavBar");

  return (
    <Box display={{ base: "block", md: "none" }} py={3}>
      <Flex justify={"space-between"}>
        <Flex justify={"space-between"}>
          {isOpen ? (
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={"Close"}
              variant="ghost"
              color="current"
              onClick={toggle}
              icon={<Icon as={FiXCircle} boxSize={7} />}
            />
          ) : (
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={"Close"}
              variant="ghost"
              color="current"
              onClick={toggle}
              icon={<Icon as={FiMenu} boxSize={7} />}
            />
          )}
          <NavLink
            to="/"
            onClick={() => setIsOpen(false)}
            className={(navData) => (navData.isActive ? "font-bold" : "")}
          >
            <LogoSVG
              style={{
                fill: logoColor,
                marginTop: 10,
                height: 40,
                width: 150,
              }}
            />
          </NavLink>
        </Flex>

        <Box>
          <IconButton
            size="md"
            fontSize="lg"
            aria-label={"Close"}
            variant="ghost"
            color="current"
            name="Instagram Button"
            onClick={() => gaEventTracker("Instagram")}
            icon={<Icon as={FiInstagram} boxSize={7} />}
          />
          <ColorModeSwitcher justifySelf="flex-end" />
        </Box>
      </Flex>
    </Box>
  );
};

const MenuLinks = ({ isOpen, setIsOpen }) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const logoColor = useColorModeValue("black", "white");
  const gaEventTracker = useAnalyticsEventTracker("NavBar");

  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%" }}
      py={isMobile ? 0 : 6}
    >
      <Box
        justifyContent={["center", "center", "space-between", "space-between"]}
        display="flex"
      >
        {!isMobile ? (
          <button>
            <NavLink
              to="/"
              onClick={() => setIsOpen(false)}
              className={(navData) => (navData.isActive ? "font-bold" : "")}
            >
              <LogoSVG
                style={{
                  fill: logoColor,
                  marginTop: 5,
                  paddingRight: 10,
                  width: "90%",
                }}
              />
            </NavLink>
          </button>
        ) : null}

        <Box>
          <Stack
            spacing={8}
            align="center"
            direction={["column", "row", "row", "row"]}
            pt={[2, 3, 2, 2]}
            pb={[4, 3, 2, 2]}
          >
            <button>
              <NavLink
                to="/"
                onClick={() => setIsOpen(false)}
                className={(navData) =>
                  " items-center tracking-widest font-thin hover:underline " +
                  (navData.isActive ? "font-bold" : "")
                }
              >
                HOME
              </NavLink>
            </button>

            {/* <button>
              <NavLink
                to="blog"
                onClick={() => setIsOpen(false)}
                className={(navData) =>
                  " items-center  tracking-widest font-thin hover:underline " +
                  (navData.isActive ? "font-bold" : "")
                }
              >
                BLOG
              </NavLink>
            </button> */}
            <button>
              <NavLink
                to="photography"
                onClick={() => setIsOpen(false)}
                className={(navData) =>
                  " items-center  tracking-widest font-thin hover:underline " +
                  (navData.isActive ? "font-bold" : "")
                }
              >
                PHOTOGRAPHY
              </NavLink>
            </button>
            <button>
              <NavLink
                to="contact"
                onClick={() => setIsOpen(false)}
                className={(navData) =>
                  " items-center  tracking-widest font-thin hover:underline " +
                  (navData.isActive ? "font-bold" : "")
                }
              >
                CONTACT
              </NavLink>
            </button>
            {!isMobile ? (
              <Box>
                <IconButton
                  size="md"
                  fontSize="lg"
                  variant="ghost"
                  color="current"
                  onClick={() => {
                    openInNewTab(
                      "https://www.instagram.com/cristhianbxcx/",
                      isMobile
                    );
                    gaEventTracker("Instagram");
                  }}
                  icon={<Icon as={FiInstagram} boxSize={7} />}
                />

                <ColorModeSwitcher />
              </Box>
            ) : null}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

const openInNewTab = (url, isMobile) => {
  if (isMobile) {
    setTimeout(() => {
      window.open(url, "_top");
    });
  } else {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }
};

const NavBarContainer = ({ children }) => {
  const color = useColorModeValue(
    "rgba(255, 255, 255, 0.96)",
    "rgba(26, 32, 44, 0.96)"
  );

  return (
    <Box bg={color} className="top-0 bg-opacity-75" zIndex={"1"}>
      <Container style={{ opacity: 0.75 }} maxW={["100%", "90%"]}>
        {children}
      </Container>
    </Box>
  );
};
