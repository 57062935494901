import {
  Box,
  Container,
  Flex,
  Image,
  Text,
  Button,
  Textarea,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FiMail, FiUser } from "react-icons/fi";
import { styles } from "../theme/styles";
import { useColorModeValue } from "@chakra-ui/react";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/2ffaf4b0-104f-11ed-a9ac-1bde1534b8d2";

export default function Contact() {
  useEffect(() => {
    document.title = "Contact";
  }, []);

  return (
    <>
      <Container maxW={["90%", "xl%"]} align={"center"}>
        <Flex
          maxW="container.lx"
          direction={["column", "row", "row", "row"]}
          wrap={"wrap"}
          display={"flex"}
          justify={"space-around"}
          pt={2}
        >
          <Box>
            <Text
              style={{
                fontFamily: "VelinoW01",
                letterSpacing: 2,
                fontSize: 42,
                maxWidth: 300,
                paddingBottom: 8,
              }}
            >
              CRISTHIAN BENITEZ
            </Text>
            <Text style={styles.detailText}>SOFTWARE ENGINEER</Text>
            <Text style={styles.detailTextSmall}>PHOTOGRAPHER</Text>
            <Text style={styles.detailTextSmallX2}>
              WANNA BE GRAPHIC DESIGNER
            </Text>
          </Box>
        </Flex>

        <Form />
        <Image
          objectFit="cover"
          py={8}
          src={require("../images/aboutBanner.jpg")}
        />
      </Container>
    </>
  );
}

function Form() {
  const bgColor = useColorModeValue("black", "white");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return <Text style={styles.thankYouText}>Thank You!</Text>;
  }

  return (
    <Container maxW="600px" pt={8} pb={10}>
      <Box
        bg={bgColor === "white" ? "#00000030" : "#00000007"}
        paddingX={[8, 10, 16, 20]}
        paddingY={6}
        borderRadius={8}
      >
        <Text mb={8} style={styles.formText}>
          CONTACT FORM
        </Text>

        <form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST">
          <InputGroup style={styles.inputFields}>
            <InputLeftElement
              pointerEvents="none"
              children={<FiUser color="gray.300" />}
            />
            <Input
              type="text"
              placeholder="Name"
              name="Name"
              id="name"
              required
            />
          </InputGroup>

          <InputGroup style={styles.inputFields}>
            <InputLeftElement
              pointerEvents="none"
              children={<FiMail color="gray.300" />}
            />
            <Input
              name="Email"
              id="email"
              type="email"
              required
              placeholder="Email"
            />
          </InputGroup>

          <InputGroup style={styles.inputFields}>
            <Textarea
              placeholder="Have something to say? Just let me know!"
              name="message"
              required
            />
          </InputGroup>

          <Box align="center" pt={8}>
            <Button
              colorScheme="gray"
              variant="outline"
              width="200px"
              type="submit"
              value="Download CTA"
            >
              <Text className={" items-center  tracking-widest font-thin "}>
                SEND
              </Text>
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}
