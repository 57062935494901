import { Box, ChakraProvider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { usePageTracking } from "./Utils/usePageTracking";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import { NavBar } from "./components/NavBar";
import SinglePost from "./components/SinglePost";
import About from "./Screens/About";
// import Blog from "./Screens/Blog";
import Music from "./Screens/Blog/Music";
import Photography from "./Screens/Photography";
import Thoughts from "./Screens/Blog/Thoughts";
import Contact from "./Screens/Contact";
import Home from "./Screens/Home";
import theme from "./theme";
import ReactGA from "react-ga";

const App = () => {
  const { height } = useWindowSize();
  usePageTracking();

  const TRACKING_ID = "UA-242722185-3"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  return (
    <ChakraProvider theme={theme}>
      <NavBar />
      <Box style={{ minHeight: height - 286 }}>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<About />} path="/about" />
          {/* <Route element={<Blog />} path="/blog" /> */}
          <Route element={<SinglePost />} path="/blog/:slug" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<Photography />} path="/photography" />
          <Route element={<Music />} path="/blog/music" />
          <Route element={<Thoughts />} path="/blog/thoughts" />
        </Routes>
      </Box>
      <Footer />
    </ChakraProvider>
  );
};

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default App;
