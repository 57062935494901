/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  CircularProgress,
  Container,
  Flex,
  Spacer,
  Text,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuButton,
  Menu,
  Box,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import "pro-gallery/dist/statics/main.css";
import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useWindowSize } from "usehooks-ts";
import sanityClient from "../client";
import ErrorCat from "../components/ErrorCat";
import { Fraction } from "fractional";
import PhotoAlbum from "react-photo-album";
import { styles } from "./Blog/BlogStyles";

export default function Photography() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [photoArray, setPhotoArray] = useState([]);
  const [photoArraySorted, setphotoArraySorted] = useState([]);
  const [dateSortModePhotography, setdateSortModePhotography] = useState(null);
  const { width } = useWindowSize();
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const isDev = process.env.NODE_ENV === "development";

  const photoLimit = 24;

  useEffect(() => {
    document.title = "Photography";

    const storedPreference = localStorage.getItem("dateSortModePhotography");
    if (storedPreference) {
      setdateSortModePhotography(storedPreference);
    }

    sanityClient
      .fetch(
        `*[_type == "photos"] | order(photograph.exif.DateTimeOriginal) {
        ...,
        "photograph": photograph.asset->{
          url,
          metadata {
            exif,
            dimensions,
          }
        }
      }`
      )
      .then((data) => {
        setNumberOfPages(Math.ceil(data.length / photoLimit));
        createPhotoArray(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setHasError(true);
        console.log("ERROR CAUGHT: ", error);
      });
  }, []);

  useEffect(() => {
    if (dateSortModePhotography === "desc") {
      localStorage.setItem("dateSortModePhotography", "desc");
    } else {
      localStorage.setItem("dateSortModePhotography", "asc");
    }
    if (photoArray) {
      tryToSort(dateSortModePhotography);
    }
  }, [dateSortModePhotography, photoArray, pageNumber]);

  useEffect(() => {
    // Scroll to the top after the pageNumber state has been updated
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 5); // Delay of 0 milliseconds to wait for layout changes
  }, [pageNumber]);

  const eventsListener = (event) => {
    setCurrentImage(event.index);
    setViewerIsOpen(true);
  };

  const createPhotoArray = (data) => {
    const localPhotoArray = data.map((image) => {
      const dimensions = image.photograph.metadata.dimensions;
      const exif = image.photograph.metadata.exif;
      const { aspectRatio, height, width } = dimensions;
      const multiplier = 500 / (aspectRatio * height);

      return {
        itemId: image.id,
        tags: image.tags,
        url: image.photograph.url,
        src: `${image.photograph.url}?hrect=0,0,620,400&h=900`,
        width: Math.round(multiplier * width * aspectRatio),
        height: Math.round(multiplier * height * aspectRatio),
        title: image.title,
        metaData: {
          type: "image",
          exif: exif,
          width: Math.round(multiplier * width * aspectRatio),
          height: Math.round(multiplier * height * aspectRatio),
          title: `${image.title}`,
          description: image.description,
          camera: image.camera,
          sortDate:
            image.date || exif.DateTimeDigitized || exif.DateTimeOriginal,
          film: image.film,
        },
      };
    });

    setPhotoArray(localPhotoArray);
  };

  const getTitle = () => {
    return (
      <Text style={styles.titleHeader}>
        TITLE
        {
          <a style={styles.titleText}>
            {photoArraySorted[currentImage]?.metaData?.title || null}
          </a>
        }
      </Text>
    );
  };

  const createMetaData = () => {
    const { metaData } = photoArraySorted[currentImage] || {};
    const { exif } = metaData || {};

    if (exif) {
      let exposure = null;

      if (exif.ExposureTime) {
        exposure = new Fraction(exif.ExposureTime, 1);
        exposure = exposure.numerator + "/" + exposure.denominator;
      }

      return (
        <Box display="flex">
          {metaData.camera && (
            <Box pr={4}>
              <Text style={styles.metaDataHeader}>
                CAMERA
                <Text style={styles.metaDataText}>{metaData.camera}</Text>
              </Text>
            </Box>
          )}

          {metaData.film && (
            <Box pr={4}>
              <Text style={styles.metaDataHeader}>
                FILM (35MM)
                <Text style={styles.metaDataText}>{metaData.film}</Text>
              </Text>
            </Box>
          )}

          {exif.ISO && (
            <Box pr={4}>
              <Text style={styles.metaDataHeader}>
                ISO
                <Text style={styles.metaDataText}>{exif.ISO}</Text>
              </Text>
            </Box>
          )}

          {exif.FNumber && (
            <Box pr={4}>
              <Text style={styles.metaDataHeader}>
                APERTURE
                <Text style={styles.metaDataText}>ƒ{exif.FNumber}</Text>
              </Text>
            </Box>
          )}

          {exposure && (
            <Box pr={4}>
              <Text style={styles.metaDataHeader}>
                EXPOSURE
                <Text style={styles.metaDataText}>{exposure}s</Text>
              </Text>
            </Box>
          )}

          {exif.FocalLengthIn35mmFormat || exif.FocalLength ? (
            <Box pr={4}>
              <Text style={styles.metaDataHeader}>
                FOCAL LENGTH
                <Text style={styles.metaDataText}>
                  {exif.FocalLengthIn35mmFormat || exif.FocalLength} mm
                </Text>
              </Text>
            </Box>
          ) : null}

          {getLensModel(metaData)}
        </Box>
      );
    }
  };

  const getLensModel = (metaData) => {
    const { exif } = metaData;
    let lensModel;

    if (metaData?.camera?.includes("CANON AE1")) {
      lensModel = "Canon 50mm F/1.8 FD";
    }

    if (metaData?.camera?.includes("YASHICA T3")) {
      lensModel = "Carl Zeiss F2.8 35mm";
    }

    if (metaData?.camera?.includes("OPTIMA 1535")) {
      lensModel = "AGFA SOLITAR S 2.8/40mm";
    }

    if (metaData?.camera?.includes("GS645S")) {
      lensModel = "Fujinon W 60mm 4";
    }

    if (lensModel || exif.LensModel) {
      return (
        <Box pr={4}>
          <Text style={styles.metaDataHeader}>
            LENS
            <Text style={styles.metaDataText}>
              {lensModel || exif.LensModel}
            </Text>
          </Text>
        </Box>
      );
    }
  };

  const getTagColor = (value) => {
    if (value.includes("Film")) {
      return "yellow";
    }
    if (value.includes("City")) {
      return "orange";
    }
    if (value.includes("Car")) {
      return "purple";
    }
    if (value.includes("People")) {
      return "red";
    }
    if (value.includes("Everyday")) {
      return "pink";
    }
    if (value.includes("Building")) {
      return "gray";
    }
    if (value.includes("Night")) {
      return "blue";
    }
    return "green";
  };

  const getDescription = () => {
    if (photoArraySorted[currentImage]) {
      return (
        <Flex
          align={"center"}
          style={{
            width: width - 40,
          }}
        >
          {createMetaData()}

          <Spacer />
          {photoArraySorted[currentImage].tags?.map((tag) => (
            <Badge
              variant="outline"
              colorScheme={getTagColor(tag.value)}
              style={styles.badgePadding}
              key={tag.label}
            >
              {tag.label}
            </Badge>
          ))}
        </Flex>
      );
    }
    return null;
  };

  const tryToSort = (sortType) => {
    const localData = [...photoArray] || null;

    if (sortType) {
      setdateSortModePhotography(sortType);
    }
    if (sortType === "desc") {
      localData.sort(function (a, b) {
        return new Date(a.metaData?.sortDate) - new Date(b.metaData?.sortDate);
      });
    } else {
      localData.sort(function (a, b) {
        return new Date(b.metaData?.sortDate) - new Date(a.metaData?.sortDate);
      });
    }

    const pagedLocalData = localData.slice(
      pageNumber * photoLimit,
      (pageNumber + 1) * photoLimit
    );

    setphotoArraySorted(pagedLocalData);
  };

  const renderNewBadge = (photo) => {
    const today = new Date();
    const oneMonthAgo = new Date(today.setDate(today.getDate() - 25));
    const itemDate = new Date(photo.metaData.sortDate) || null;

    if (itemDate) {
      if (oneMonthAgo <= itemDate)
        return (
          <Badge variant="solid" className="absolute m-2" colorScheme="blue">
            NEW
          </Badge>
        );
    }
  };

  const renderPhoto = ({
    photo,
    imageProps: { alt, style, ...restImageProps },
  }) => {
    return (
      <div
        style={{
          boxSizing: "content-box",
          width: style?.width,
          backgroundColor: "black",
        }}
      >
        <div className="bg-black w-full h-full hover:opacity-50 transition-bg duration-700  pointer-events-auto">
          {renderNewBadge(photo)}

          <img
            alt={alt}
            style={{
              ...style,
              width: "100%",
              padding: 0,
            }}
            {...restImageProps}
          />
        </div>
      </div>
    );
  };

  const handlePageChange = (newPage) => {
    setPageNumber(newPage - 1);
  };

  if (isLoading)
    return (
      <Flex align={"center"} justify={"space-around"} pt={100}>
        <CircularProgress isIndeterminate color="blue.300" size="50px" />
      </Flex>
    );
  if (hasError) return <ErrorCat />;

  return (
    <Container maxW={["90%", "xl%"]} mb={10}>
      <Flex gap="2" mb={4} mt={0}>
        {/* <Flex pt={6}>
          <Badge>
            Showing {photoArraySorted.length} of {photoArray.length} images
          </Badge>
        </Flex> */}
        <Spacer />
        <Menu>
          <MenuButton as={Button} colorScheme="blue">
            Sort By
          </MenuButton>
          <MenuList minWidth="240px">
            <MenuOptionGroup
              defaultValue={dateSortModePhotography}
              title="Date"
              type="radio"
            >
              <MenuItemOption value="asc" onClick={() => tryToSort("asc")}>
                Ascending
              </MenuItemOption>
              <MenuItemOption value="desc" onClick={() => tryToSort("desc")}>
                Descending
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Flex>

      {viewerIsOpen && (
        <Lightbox
          imageTitle={getTitle()}
          imageCaption={getDescription()}
          mainSrc={
            isDev
              ? photoArraySorted[currentImage]?.src
              : photoArraySorted[currentImage]?.url
          }
          nextSrc={
            photoArraySorted[(currentImage + 1) % photoArraySorted.length]?.url
          }
          prevSrc={
            photoArraySorted[
              (currentImage + photoArraySorted.length - 1) %
                photoArraySorted.length
            ]?.url
          }
          onCloseRequest={() => setViewerIsOpen(false)}
          onMovePrevRequest={() =>
            setCurrentImage(
              (currentImage + photoArraySorted.length - 1) %
                photoArraySorted.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photoArraySorted.length)
          }
        />
      )}

      <PhotoAlbum
        layout="rows"
        photos={photoArraySorted}
        onClick={eventsListener}
        targetRowHeight={400}
        spacing={8}
        renderPhoto={renderPhoto}
      />

      <Flex justify="center" gap="2" mb={4} pt={6}>
        <ButtonGroup size="sm" variant="outline" isAttached={false}>
          {Array.from({ length: numberOfPages }, (_, index) => (
            <Button
              key={index + 1}
              size="lg"
              fontSize="18px"
              variant={pageNumber === index ? "outline" : "solid"}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Button>
          ))}
        </ButtonGroup>
      </Flex>
    </Container>
  );
}
