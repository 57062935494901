import { Box, Center } from "@chakra-ui/react";
import { getImageDimensions } from "@sanity/asset-utils";
import { default as urlBuilder } from "@sanity/image-url";
import React from "react";

const CImageComponent = ({ value }) => {
  const { width, height } = getImageDimensions(value);
  const justifyType = value.align || "center";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: justifyType,
      }}
    >
      <img
        src={urlFor(value.asset).height(200).url()}
        alt={value.alt || " "}
        loading="lazy"
        style={{
          aspectRatio: width / height,
        }}
      />
    </div>
  );
};

const urlFor = (source) =>
  urlBuilder({ projectId: "5x8bcyoh", dataset: "production" }).image(source);

const PortableTextComponents = {
  block: {
    normal: ({ children }) => (
      <p className="mb-6 whitespace-pre-line  ">{children}</p>
    ),
    h3: ({ children }) => <h1 className="text-2xl">{children}</h1>,
    blockquote: ({ children }) => (
      <Center my={-3}>
        <Box width={400}>
          <blockquote className="relative px-4 my-1 text-md italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500">
            <svg
              aria-hidden="true"
              className="w-4 h-4 text-gray-400 dark:text-gray-600"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <p className="font-serif">"{children}"</p>
          </blockquote>
        </Box>
      </Center>
    ),

    // Ex. 2: rendering custom styles
    customHeading: ({ children }) => (
      <h2 className="text-lg text-primary text-purple-700">{children}</h2>
    ),
  },
  types: {
    image: CImageComponent,
    callToAction: ({ value, isInline }) =>
      isInline ? (
        <a href={value.url}>{value.text}</a>
      ) : (
        <div className="callToAction">{value.text}</div>
      ),
  },

  marks: {
    highlight: ({ children }) => <em className="bg-indigo-300">{children}</em>,
    link: ({ children, value }) => {
      const rel = !value.href.startsWith("/")
        ? "noreferrer noopener"
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

export default PortableTextComponents;
