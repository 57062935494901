import { Box, Container, ScaleFade, Text } from "@chakra-ui/react";
import Lottie from "lottie-react";
import { React } from "react";
import catError from "../images/cat-error.json";

export default function ErrorCat() {
  return (
    <ScaleFade initialScale={0.6} in={true}>
      <Container maxW="lg" mb={10}>
        <Box
          p="30px"
          color="white"
          mt="4"
          rounded="md"
          shadow="md"
          bg="gray.700"
        >
          <Text
            style={{
              textAlign: "center",
              padding: 2,
              fontSize: 22,
            }}
          >
            There was an error loading the current page. Please Try again later!
          </Text>
          <Lottie
            animationData={catError}
            loop={true}
            style={{ margin: -40 }}
          />
        </Box>
      </Container>
    </ScaleFade>
  );
}
