import React from "react";
import { useColorMode, useColorModeValue, IconButton } from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";
import useAnalyticsEventTracker from "./Utils/useAnalyticsEventTracker";
export const ColorModeSwitcher = () => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const gaEventTracker = useAnalyticsEventTracker("NavBar");

  return (
    <IconButton
      size="md"
      fontSize="lg"
      aria-label={`Switch to ${text} mode`}
      variant="ghost"
      color="current"
      onClick={() => {
        toggleColorMode();
        gaEventTracker(`Switched to ${text} mode`);
      }}
      icon={<SwitchIcon />}
    />
  );
};
