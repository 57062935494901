import { Container, Divider } from "@chakra-ui/react";
import React from "react";
import { BlogNavBar } from "../../components/BlogNavBar";

export default function Thoughts() {
  return (
    <Container maxW={["90%", "xl%"]}>
      <BlogNavBar />
      <Divider pt={5} />
    </Container>
  );
}
