import {
  AspectRatio,
  Box,
  Button,
  Container,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useAnalyticsEventTracker from "../Utils/useAnalyticsEventTracker";

export default function Home() {
  const gaEventTracker = useAnalyticsEventTracker("Clicks");

  useEffect(() => {
    document.title = "Cristhian's Art Place";
  }, []);

  return (
    <Container maxW={["100%", "90%"]}>
      <Flex
        direction={["column", "row", "row", "row"]}
        wrap={"wrap"}
        display={"flex"}
        justify={"space-around"}
        align={"center"}
        pt={4}
      >
        <AspectRatio w={["100%", "49%", "49%", "33%"]} ratio={[1, 1, 1, 1]}>
          <Image
            pb={4}
            objectFit="cover"
            alt="Cristhian's Art Space Photography Image 1"
            src={require("../images/Intro1.webp")}
          />
        </AspectRatio>
        <AspectRatio w={["100%", "49%", "49%", "33%"]} ratio={[1, 1, 1, 1]}>
          <Image
            pb={4}
            objectFit="cover"
            alt="Cristhian's Art Space Photography Image 2"
            src={require("../images/Intro2.webp")}
          />
        </AspectRatio>
        <AspectRatio
          w={["100%", "49%", "100%", "33%"]}
          ratio={[1, 1, 3 / 2, 1]}
        >
          <Image
            pb={4}
            objectFit="cover"
            alt="Cristhian's Art Space Photography Image 3"
            src={require("../images/Intro3.webp")}
          />
        </AspectRatio>
      </Flex>

      <Box align={"center"} p={3}>
        {/* <Link to="/blog">
          <Button
            colorScheme="gray"
            variant="outline"
            width="100%"
            maxW="200px"
            mx={5}
            my={5}
            onClick={() => gaEventTracker("home-BLOG")}
          >
            <Text className={" items-center  tracking-widest font-thin "}>
              READ BLOG
            </Text>
          </Button>
        </Link> */}
        <Link to="/photography">
          <Button
            colorScheme="gray"
            variant="outline"
            width="100%"
            maxW="200px"
            mx={5}
            onClick={() => gaEventTracker("home_PHOTOGRAPHY")}
          >
            <Text className={" items-center  tracking-widest font-thin "}>
              PHOTOGRAPHY
            </Text>
          </Button>
        </Link>
      </Box>
    </Container>
  );
}
