import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  CircularProgress,
  Container,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { PortableText } from "@portabletext/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorCat from "../components/ErrorCat";
import { NavLink } from "react-router-dom";
import VinylCover from "./VinylCover";
import sanityClient from "../client";
import PortableTextCustom from "./PortableText";

export default function SinglePost() {
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
          ...,
      title,
      _id,
      slug,
      mainImage{
        asset->{
          _id,
          url
        }
      },
      body,
      "name": author->name,
      "authorImage": author->image
    }`
      )
      .then((data) => {
        setIsLoading(false);
        setSinglePost(data[0]);
        document.title = "Blog - " + data[0].title;
      })
      .catch((error) => {
        setIsLoading(false);
        setHasError(true);
      });
    // }
  }, [slug]);

  if (isLoading && !singlePost)
    return (
      <Flex align={"center"} justify={"space-around"} pt={100}>
        <CircularProgress isIndeterminate color="blue.300" size="50px" />
      </Flex>
    );

  if (hasError) return <ErrorCat />;

  let now = singlePost ? new Date(singlePost.date) : new Date();
  let onejan = new Date(now.getFullYear(), 0, 1);
  let week = Math.ceil(
    ((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
  );

  return (
    <Container maxW={["xl", "2xl", "2xl", "4xl"]} mt={-2}>
      <Breadcrumb
        spacing="8px"
        mb={8}
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem as={NavLink} to="/blog">
          <BreadcrumbLink>Blog</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{singlePost?.title}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Container maxW={"100%"} borderWidth="1px" borderRadius="lg" p={8} mb={8}>
        {singlePost && (
          <Container maxW={["3xl"]}>
            <Center>
              <VinylCover
                url={singlePost.mainImage.asset.url}
                alt={singlePost.mainImage.asset._id}
              />
            </Center>

            <Heading>{singlePost.title}</Heading>

            <Box
              letterSpacing="wide"
              color="gray.400"
              fontWeight="semibold"
              fontSize="m"
            >
              {singlePost.album} &bull; {singlePost.Artist}
            </Box>

            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="m"
              textTransform="uppercase"
            >
              Song of the Week &bull; Week {week} &bull; 2022
            </Box>

            <Box mt={2} overflow="hidden">
              <PortableText
                value={singlePost.body}
                projectId={"5x8bcyoh"}
                dataset="production"
                components={PortableTextCustom}
              />
            </Box>
            {singlePost.spotify && (
              <Box margin="2">
                <iframe
                  title="Spotify Audio Player"
                  src={singlePost.spotify}
                  width="100%"
                  height="80"
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                />
              </Box>
            )}
            {singlePost.youtube && (
              <Box margin="2">
                <iframe
                  width="100%"
                  height="315"
                  src={singlePost.youtube}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Box>
            )}
          </Container>
        )}
      </Container>
    </Container>
  );
}
