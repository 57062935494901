import React from "react";

function Vinyl(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="600 0 162.64 700"
      {...props}
    >

      <defs>

        <path
          id="path4351"
          fill="#222f5e"
          d="M1079.4 370.4a365.25 376.81 0 11-730.49 0 365.25 376.81 0 11730.49 0z"
          transform="matrix(-.16038 -.17816 .1727 -.15546 893.59 878.92)"
        ></path>
        <filter id="shadow">
          <feOffset result="offOut" in="SourceAlpha" dx="3" dy="3" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />

        </filter>
        <filter id="filter4177-4" colorInterpolationFilters="sRGB">
          <feGaussianBlur
            in="SourceGraphic"
            result="fbSourceGraphic"
            stdDeviation="5 0.01"
          ></feGaussianBlur>
          <feColorMatrix
            in="fbSourceGraphic"
            result="fbSourceGraphicAlpha"
            values="0 0 0 -1 0 0 0 0 -1 0 0 0 0 -1 0 0 0 0 1 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g transform="translate(-462.62 -348.03)">
        <g fill="#000" fillOpacity="0.988" transform="matrix(1 0 0 -1 -2 6)">
          <path d="M992.92-875.74l-1.25 2.751a.773.773 0 00-.148.199.446.446 0 00-.05.201c0 .132.05.232.15.3.1.066.216.133.35.2.065 0 .115.017.148.05.034.032.084.048.151.048h.199c.134 0 .218-.05.252-.15.033-.1.083-.215.148-.347l.9-1.85.45-.926 4.202 1.424-1.75 3.75c-.4.8-.901 1.383-1.501 1.75-.6.368-1.267.551-2 .551a1.64 1.64 0 01-.4-.05 1.624 1.624 0 00-.399-.05 1.98 1.98 0 00-.401-.151 2.018 2.018 0 01-.398-.148 3.415 3.415 0 01-1.601-1.302c-.4-.6-.6-1.3-.6-2.101 0-.267.033-.55.1-.85.066-.3.166-.616.3-.948l1.398-2.801z"></path>
          <path d="M992.92-875.74l-1.25 2.751a.773.773 0 00-.148.199.446.446 0 00-.05.201c0 .132.05.232.15.3.1.066.216.133.35.2.065 0 .115.017.148.05.034.032.084.048.151.048h.199c.134 0 .218-.05.252-.15.033-.1.083-.215.148-.347l.9-1.85.45-.926 4.202 1.424-1.75 3.75c-.4.8-.901 1.383-1.501 1.75-.6.368-1.267.551-2 .551a1.64 1.64 0 01-.4-.05 1.624 1.624 0 00-.399-.05 1.98 1.98 0 00-.401-.151 2.018 2.018 0 01-.398-.148 3.415 3.415 0 01-1.601-1.302c-.4-.6-.6-1.3-.6-2.101 0-.267.033-.55.1-.85.066-.3.166-.616.3-.948l1.398-2.801z"></path>
        </g>
        <g
          fill="#000"
          fillOpacity="0.988"
          transform="rotate(-50.942 2835.534 5.08)"
        >
          <path d="M992.92-875.74l-1.25 2.751a.773.773 0 00-.148.199.446.446 0 00-.05.201c0 .132.05.232.15.3.1.066.216.133.35.2.065 0 .115.017.148.05.034.032.084.048.151.048h.199c.134 0 .218-.05.252-.15.033-.1.083-.215.148-.347l.9-1.85.45-.926 4.202 1.424-1.75 3.75c-.4.8-.901 1.383-1.501 1.75-.6.368-1.267.551-2 .551a1.64 1.64 0 01-.4-.05 1.624 1.624 0 00-.399-.05 1.98 1.98 0 00-.401-.151 2.018 2.018 0 01-.398-.148 3.415 3.415 0 01-1.601-1.302c-.4-.6-.6-1.3-.6-2.101 0-.267.033-.55.1-.85.066-.3.166-.616.3-.948l1.398-2.801z"></path>
          <path d="M992.92-875.74l-1.25 2.751a.773.773 0 00-.148.199.446.446 0 00-.05.201c0 .132.05.232.15.3.1.066.216.133.35.2.065 0 .115.017.148.05.034.032.084.048.151.048h.199c.134 0 .218-.05.252-.15.033-.1.083-.215.148-.347l.9-1.85.45-.926 4.202 1.424-1.75 3.75c-.4.8-.901 1.383-1.501 1.75-.6.368-1.267.551-2 .551a1.64 1.64 0 01-.4-.05 1.624 1.624 0 00-.399-.05 1.98 1.98 0 00-.401-.151 2.018 2.018 0 01-.398-.148 3.415 3.415 0 01-1.601-1.302c-.4-.6-.6-1.3-.6-2.101 0-.267.033-.55.1-.85.066-.3.166-.616.3-.948l1.398-2.801z"></path>
        </g>
        <path
          style={{
            blockProgression: "tb",
            WebkitTextIndent: "0",
            textIndent: "0",
            WebkitTextTransform: "none",
            textTransform: "none",
            backgroundColor: 'red'
          }}
          fill="#000"
          stroke="#000"
          strokeLinecap="round"
          strokeOpacity="0.85"
          strokeWidth="5"
          filter="url(#shadow)"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.95185 0 0 .92263 164.22 356.29)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.9404 0 0 .91154 172.39 360.4)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.92677 0 0 .89833 182.12 365.29)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.91314 0 0 .88511 191.86 370.18)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.89951 0 0 .8719 201.59 375.08)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.88588 0 0 .85869 211.32 379.97)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.87225 0 0 .84548 221.05 384.86)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.85862 0 0 .83227 230.79 389.76)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.84499 0 0 .81906 240.52 394.65)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.83137 0 0 .80585 250.25 399.54)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.80411 0 0 .77943 269.72 409.33)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.79048 0 0 .76622 279.45 414.22)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.77685 0 0 .75301 289.18 419.12)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.76322 0 0 .7398 298.91 424.01)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.74959 0 0 .72659 308.65 428.9)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.73596 0 0 .71338 318.38 433.8)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.72233 0 0 .70017 328.11 438.69)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.69508 0 0 .67374 347.58 448.48)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.68145 0 0 .66053 357.31 453.37)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.66782 0 0 .64732 367.04 458.26)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.65419 0 0 .63411 376.77 463.16)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.64056 0 0 .6209 386.51 468.05)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.62693 0 0 .60769 396.24 472.94)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.6133 0 0 .59448 405.97 477.84)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.59967 0 0 .58127 415.7 482.73)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.58604 0 0 .56806 425.44 487.62)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.55879 0 0 .54164 444.9 497.41)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.54516 0 0 .52843 454.63 502.3)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.53153 0 0 .51522 464.37 507.2)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.5179 0 0 .50201 474.1 512.09)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.50427 0 0 .48879 483.83 516.98)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.49064 0 0 .47558 493.56 521.88)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.47701 0 0 .46237 503.3 526.77)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.46338 0 0 .44916 513.03 531.66)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.44976 0 0 .43595 522.76 536.55)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.43613 0 0 .42274 532.49 541.45)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.4225 0 0 .40953 542.23 546.34)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.39524 0 0 .38311 561.69 556.13)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.38161 0 0 .3699 571.43 561.02)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.36798 0 0 .35669 581.16 565.91)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.35435 0 0 .34348 590.89 570.81)"
        ></path>
        <path
          fill="#000"
          stroke="#fff"
          strokeLinecap="round"
          strokeOpacity="0.123"
          strokeWidth="3.293"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.34072 0 0 .33027 600.62 575.7)"
        ></path>
        <path
          fill="#000"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(.27258 0 0 .26421 649.29 600.17)"
        ></path>
        <path
          style={{
            blockProgression: "tb",
            WebkitTextIndent: "0",
            textIndent: "0",
            WebkitTextTransform: "none",
            textTransform: "none",
          }}
          fillOpacity="0"
          d="M750.86 693.62c-1.211 25.625 8.053 49.326 24.083 66.946s38.823 29.183 64.578 30.4c25.755 1.217 49.583-8.144 67.202-24.173 17.62-16.03 29.078-38.751 30.288-64.376 1.21-25.625-8.053-49.326-24.083-66.946s-38.87-29.15-64.625-30.367-49.536 8.11-67.156 24.14c-17.62 16.03-29.077 38.751-30.288 64.376z"
          color="#000"
        ></path>
        <path
          fill="#5d5d5d"
          d="M1050.6 517.07c-18.482 45.081-45.227 85.679-78.275 119.77l-255.33-249.91-339.43-163.2c18.482-45.081 45.227-85.679 78.275-119.77l257.48 249.91z"
          filter="url(#filter4177-4)"
          opacity="0.767"
          transform="matrix(.95185 0 0 .92263 164.22 356.29)"
        ></path>
        <path
          fill="#222f5e"
          d="M1079.4 370.4c0 208.11-163.53 376.81-365.25 376.81S348.9 578.51 348.9 370.4 512.43-6.41 714.15-6.41s365.25 168.7 365.25 376.81z"
          transform="matrix(-.16038 -.17816 .1727 -.15546 894.5 882.84)"
        ></path>
        <path
          fill="#000"
          d="M405.3 354a22.652 14 0 11-45.305 0 22.652 14 0 1145.305 0z"
          transform="matrix(.45249 0 0 .73214 670.79 438.85)"
        ></path>
      </g>
    </svg>
  );
}


export default Vinyl;

