export const styles = {
  //Contact Page
  inputFields: {
    marginTop: 10,
    marginBottom: 2,
  },
  formText: {
    letterSpacing: 3,
    fontWeight: 200,
    textAlign: "center",
    padding: 6,
  },
  formBox: {
    paddingTop: 8,
  },

  //About
  detailText: {
    letterSpacing: 3,
    fontWeight: 200,
    textAlign: "center",
    padding: 2,
    fontSize: 22,
  },
  detailTextSmall: {
    letterSpacing: 3,
    fontWeight: 200,
    textAlign: "center",
    padding: 2,
    fontSize: 16,
  },
  detailTextSmallX2: {
    letterSpacing: 3,
    fontWeight: 200,
    textAlign: "center",
    padding: 4,
    fontSize: 9,
  },
  thankYouText: {
    letterSpacing: 3,
    fontWeight: 200,
    textAlign: "center",
    paddingTop: 24,
    fontSize: 38,
  },

  //Footer
  footerBox: {
    textAlign: "center",
    padding: 28,
    paddingTop: 32,
    paddingBottom: 16,
    left: 0,
    bottom: 0,
    right: 0,
  },
  footerQuote: {
    fontFamily: "VelinoW01",
    textAlign: "center",
    fontSize: 21,
  },
  footerQuoteAbout: {
    textAlign: "center",
    fontSize: 11,
    paddingTop: 8,
    letterSpacing: 2,
    fontWeight: "thin",
  },
  footerCopyright: {
    textAlign: "center",
    fontSize: 8,
    paddingTop: 18,
    letterSpacing: 2,
    fontWeight: "thin",
  },
};
