import { Container, Image, Center } from "@chakra-ui/react";
import React from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import Vinyl from "../images/Vinyl";

export default function VinylCover(props) {
  const { url } = props;

  const vinylMaxHeight = useBreakpointValue({
    base: 200,
    sm: 320,
    md: 440,
    lg: 440,
  });

  return (
    <Container pb={10}>
      <Center display={"flex"}>
        <Image
          src={url}
          maxH={[220, 280, 400, 400]}
          style={{ boxShadow: "1px 2px 9px #000" }}
        />
        <Vinyl
          style={{
            maxHeight: vinylMaxHeight,
            vinylWidth: 180,
          }}
        />
      </Center>
    </Container>
  );
}
