import { Container, Stack } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

export const BlogNavBar = () => {
  return (
    <Container
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Stack
        spacing={[6, 8, 14, 24]}
        align="center"
        direction={["column", "row", "row", "row"]}
        pt={[2, 3, 2, 2]}
        pb={[4, 3, 2, 2]}
      >
        <NavLink
          to="/blog"
          end={true}
          className={(navData) =>
            " items-center tracking-widest font-thin hover:opacity-20 " +
            (navData.isActive ? "font-bold" : "")
          }
        >
          ALL
        </NavLink>
        {/* <NavLink
          to="/blog/photography"
          className={(navData) =>
            " items-center  tracking-widest font-thin hover:underline " +
            (navData.isActive ? "font-bold" : "")
          }
        >
          PHOTOGRAPHY
        </NavLink> */}
        <NavLink
          to="/blog/music"
          className={(navData) =>
            " items-center  tracking-widest font-thin hover:underline " +
            (navData.isActive ? "font-bold" : "")
          }
        >
          MUSIC
        </NavLink>
        <NavLink
          to="/blog/thoughts"
          className={(navData) =>
            " items-center  tracking-widest font-thin hover:underline " +
            (navData.isActive ? "font-bold" : "")
          }
        >
          THOUGHTS
        </NavLink>
      </Stack>
    </Container>
  );
};
