import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { styles } from "../theme/styles";
import quotes from "../resources/quotes.json";

export default function Footer() {
  const logoColor = useColorModeValue("black", "white");
  const [clickNumber, setclickNumber] = useState(0);
  const [quoteMax, setQuoteMax] = useState(0);
  const toast = useToast();
  const textColor = logoColor === "white" ? "#C8C8C8" : "#3D3D3D";

  useEffect(() => {
    setQuoteMax(Math.floor(Math.random() * quotes.quotes.length));
  }, []);

  const quotePressed = () => {
    setclickNumber(clickNumber + 1);
    setQuoteMax(Math.floor(Math.random() * quotes.quotes.length));
    shouldShowToast();
  };

  const shouldShowToast = () => {
    if (clickNumber === 5) {
      toast({
        title: "Hey there curious person!  👋😄",
        description:
          "It seems like you are enjoying the quotes! I handpicked each and every single" +
          " one of these from some of my favorite songs ever. Currently there's a total of " +
          quotes.quotes.length +
          " of them, and hopefully more to come.",
        status: "info",
        // variant: "left-accent",
        duration: 18000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      backgroundColor={logoColor === "white" ? "#00000030" : "#00000010"}
      style={styles.footerBox}
    >
      <Box>
        <Container>
          <Text
            onClick={() => quotePressed()}
            style={styles.footerQuote}
            color={textColor}
            className="text-2xl uppercase"
          >
            {quotes?.quotes[quoteMax]?.quote}
          </Text>
        </Container>

        <Text
          style={styles.footerQuoteAbout}
          color={textColor}
          className="uppercase"
          onClick={() => quotePressed()}
        >
          {quotes?.quotes[quoteMax]?.source
            ? `${quotes?.quotes[quoteMax]?.source} · `
            : ""}
          {quotes?.quotes[quoteMax]?.name}
        </Text>
      </Box>
      <Text
        style={styles.footerCopyright}
        color={textColor}
        className="uppercase"
      >
        Made with ReactJS Ⓒ {new Date().getFullYear()} Cristhian
      </Text>
    </Box>
  );
}
