import React from "react";
import { Box, Container, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { styles } from "../theme/styles";

export default function About() {
  return (
    <Container maxW={["90%", "xl%"]} align={"center"}>
      <Flex
        maxW="container.lx"
        direction={["column", "row", "row", "row"]}
        wrap={"wrap"}
        display={"flex"}
        justify={"space-around"}
        pt={2}
      >
        {/* <Text style={styles.formText}>Hello, I'm Cristhian!</Text> */}
        <Box>
          <Text
            style={{
              fontFamily: "VelinoW01",
              letterSpacing: 2,
              fontSize: 34,
              maxWidth: 300,
              paddingBottom: 14,
            }}
          >
            CRISTHIAN BENITEZ
          </Text>
          <Text style={styles.detailText}>SOFTWARE ENGINEER</Text>
          <Text style={styles.detailTextSmall}>PHOTOGRAPHER</Text>
          <Text style={styles.detailTextSmall}>WEB DESIGNER</Text>
        </Box>
        <Divider pt={6} />

        <Flex
          maxW="container.lg"
          direction={["column", "row", "row", "row"]}
          wrap={"wrap"}
          display={"flex"}
          justify={"space-around"}
          pt={4}
        >
          <Text style={styles.formText}>
            Hello! If you don't already know me, I'm Cristhian! This is a space
            where I simply get to express myself with whatever I want. There is
            no structure, there are no deadlines, and no rules either, so please
            enjoy!
          </Text>
        </Flex>

        <Image
          objectFit="cover"
          py={8}
          src={require("../images/aboutBanner.jpg")}
        />
      </Flex>
    </Container>
  );
}

// import React, { useEffect, useState } from "react";
// import sanityClient from "../client";
// import bg from "../images/1631677.jpg";
// import ImageUrlBuilder from "@sanity/image-url";
// import BlockContent from "@sanity/block-content-to-react";
// import { CircularProgress, Flex } from "@chakra-ui/react";

// export default function About() {
//   const [author, setAuthor] = useState(null);

//   const builder = ImageUrlBuilder(sanityClient);
//   function urlFor(source) {
//     return builder.image(source);
//   }
//   useEffect(() => {
//     sanityClient
//       .fetch(
//         `*[_type == "author"]{
//       name,
//       bio,
//       "authorImage": image.asset->url
//     }`
//       )
//       .then((data) => setAuthor(data[0]))
//       .catch(console.error);
//   }, []);

//   if (!author) {
//     return (
//       <Flex align={"center"} justify={"space-around"} pt={100}>
//         <CircularProgress isIndeterminate color="blue.300" size="70px" />
//       </Flex>
//     );
//   }
//   return (
//     <main className="relative">
//       <img src={bg} alt="BG" className="absolute w-full" />
//       <div className="p-10 lg:pt-48 container mx-auto relative">
//         <section className="bg-blue-800 rounded-lg shadow-2xl lg:flex p-20">
//           <img
//             src={urlFor(author.authorImage).url()}
//             alt={author.name}
//             className="rounded w-32 h-32 lg:w-64 lg:h-64 mr-8"
//           />
//           <div className="text-lg flex flex-col justify-center">
//             <h1 className="text-5xl text-blue-300 mb-4">
//               This is me! I'm{" "}
//               <span className="text-blue-100">{author.name}</span>
//             </h1>
//             <div className="prose lg:prose-xl text-white">
//               <BlockContent
//                 blocks={author.bio}
//                 projectId={"5x8bcyoh"}
//                 dataset="production"
//               />
//             </div>
//           </div>
//         </section>
//       </div>
//     </main>
//   );
// }
