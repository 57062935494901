export const styles = {
  centerItem: {},
  titleHeader: {
    fontSize: 12,
    color: "yellow",
  },
  titleText: {
    fontSize: 14,
    color: "white",
    paddingLeft: 4,
  },
  metaDataHeader: {
    fontSize: 10,
    color: "yellow",
  },
  metaDataText: {
    fontSize: 10,
    color: "white",
  },
  badgePadding: {
    marginRight: 6,
  },
};
